import React from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss';

class Header extends React.Component {

  backHome = () => {
    this.props.history.push('/home');
  }
  render() {
    let className = 'clearfix';
    const pathname = this.props.location.pathname;
    if (pathname === '/home' || pathname === '/') {
      className = className + ' home';
    } else if (pathname !== '/functionIntro' && pathname !== '/about') {
      className = className + ' hidden';
    }
    return (
      <header className={className}>
        <div className="logo" onClick={this.backHome}><span className="logo-icon"></span>时光序</div>
        <ul>
          <li>
            <NavLink to="/home" activeClassName="active">首页</NavLink>
          </li>
          <li>
            <NavLink to="/functionIntro" activeClassName="active">功能介绍</NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">关于我们</NavLink>
          </li>
        </ul>
      </header>
    );
  }
}

export default Header;