import React from 'react';
import util from '../../utils';

import './index.scss';

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0
    };
  }
  componentDidMount() {
  }
  // 滚动事件处理器
  scrollHandler = (e) => {
    const direction = e.deltaY < 0 ? 'up' : 'down';
    // 向上
    let currentIndex = 0;
    if (direction === 'up') {
      if (this.state.currentIndex === 0) {
        currentIndex = 0;
      } else {
        currentIndex = this.state.currentIndex - 1;
      }
    } else {
      // 向下
      if (this.state.currentIndex === 2) {
        currentIndex = 2;
      } else {
        currentIndex = this.state.currentIndex + 1;
      }
    }
    this.setState({currentIndex});
  }
  // 节流滚动事件处理器
  throttleScroll = util.throttle(this.scrollHandler, 1500)
  backToTop = () => {
    this.setState({currentIndex: 0});
  }
  render () {
    return (
      <div className={'about-us step' + this.state.currentIndex}>
        <div className="left-rect rect"></div>
        <div className="right-rect rect"></div>
        <div className="first-page page">
          <h2 className="title">时光序的初衷：</h2>
          <p className="destiny">
            我生来便被告知，自己从来不是为赚钱而生的产品，我是为了让每一个人都能拥有帮自己管理时间的伙伴而生，是为了让时间等于价值而生，是为了让人们更好的做自己而生。 <br/>
            人生不只眼前的苟且，我希望我的伙伴你能够注重当下，注重身边的人，注重这个草木葱茏、生机蓬勃的世界。
          </p>
          <p className="destiny">
            也许以后我的样貌会转变，也许新科技会越来越深入地改变世人们的生活，但不变的是我的初衷。
            我很清楚，每一个梦想，都值得被呵护，每一双翅膀，都应该去飞翔，只要你一个点头，我们就一起创造一段美好的旅程。
          </p>
          <div className="company-info">
            <p className="company-name">花生未来（广州）科技有限公司</p>
            <p>地址：广州市天河区花城大道667号美林基业1203</p>
            <p>官网：www.shiguangxu.com</p>
            <p>邮箱：feedback@shiguangxu.com</p>
          </div>
        </div>
        {/* 版权声明 */}
        {/* <div className="copyright">
          <span className="left">花生未来（广州）科技有限公司 www.shiguangxu.com 2017-2019 &copy; All Rights Reserved</span>
          <span className="right">粤ICP备 18009397号</span>
        </div> */}
      </div>
    );
  }
}
