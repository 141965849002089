import React from 'react';
import './index.scss';

const Footer = () => {
  let gx_beianhao = "粤ICP备20003028号";
  if (window.location.host.indexOf("weilaizhushou.com") >= 0) {
    gx_beianhao = '粤ICP备20003028号-1';
  }
  else if (window.location.host.indexOf("sgx6.cn") >= 0) {
    gx_beianhao = '粤ICP备20003028号-2';
  }
  else if (window.location.host.indexOf("shiguangxu.com") >= 0) {
    gx_beianhao = '粤ICP备20003028号-3';
  }
  return (
    <div className="footer clearfix">
      <div className="company-info">花生未来（广州）科技有限公司
      {/* <a href="https://www.shiguangxu.com" target="_blank" rel="noopener noreferrer"> www.shiguangxu.com </a> */}
      &nbsp;2023 &copy; All Rights Reserved</div>
      <div className="goverment-records">
        <span className="police"></span>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010131" target="_blank" rel="noopener noreferrer">粤公网安备 44010602010131号</a>
        <span className="line"></span>
        <a className="space" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">{gx_beianhao}</a>
      </div>
    </div>
  );
}

export default Footer;