import React from 'react'

import './refactoring.scss';

export default class Content extends React.Component {
  constructor(props) {
    super(props);
    this.descRef = React.createRef();
    this.qrRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentIndex !== prevProps.currentIndex) {
      const desc = this.descRef.current;
      if (this.props.currentIndex === 0 && prevProps.currentIndex === 4) {
        desc.classList.remove('flashing1');
        desc.classList.remove('flashing2');
        desc.classList.add('fadein');
      } else {
        desc.classList.remove('fadein');
        if (!desc.classList.contains('flashing1') && !desc.classList.contains('flashing2')) {
          desc.classList.toggle('flashing1');
        } else {
          desc.classList.toggle('flashing1');
          desc.classList.toggle('flashing2');
        }
      }
    }
  }
  // 显示隐藏二维码
  toggleQR = () => {
    this.qrRef.current.classList.toggle('active');
  }
  render() {
    return (
      <div className={'func-content page' + this.props.currentIndex}>
        <div className="phone-container">
          <div className="phone">
            <div className="iphone-content iphone-content0"></div>
            <div className="iphone-content iphone-content1"></div>
            <div className="iphone-content iphone-content2"></div>
            <div className="iphone-content iphone-content3"></div>
            <div className="iphone-content iphone-content4"></div>
            <div className="iphone-bg"></div>
          </div>
        </div>
        <div className="desc-container fadein"ref={this.descRef}>
          <div className="desc desc0" >
            <h2>
              猜你所想，<br/>
              随时记录事项。
            </h2>
            <h3>语音输入</h3>
            <p>
              你不会想错过任何一个突然冒出的想法<br/>就算此时空不出完整的手，也可以长按语音输入，留存那些不经意间的闪光。
            </p>
            <h3>猜你所想</h3>
            <p>
              告别那些多余的选项，试着说：“本周六下午四点有个约会”，智能添加一条带有日期提醒的事项。
            </p>
          </div>
          <div className="desc desc1">
            <h2>
                你将不会，<br/>
                错过任何提醒。
            </h2>
            <h3>系统提醒</h3>
            <p>
              时光序提供内部弹窗、系统通知等提醒方式
            </p>
            <h3>其他提醒</h3>
            <p>
              除系统提醒外，还支持微信提醒、电话提醒、短信提醒等多种提醒方式，全方位满足您的需求。
            </p>
          </div>
          <div className="desc desc2">
            <h2>
              理清一切，<br/>做事井然有序。
            </h2>
            <h3>日周月视图</h3>
            <p>
              时光序擅长把繁琐的事项安排得瑾瑾有序，利用各类日历视图查看事务在今天、每周、每月的哪个时段密集，让你更专注最急迫的要事。
            </p>
            <h3>倒数视图</h3>
            <p>
              采用倒数的形式呈现重要事项距离你的天数，做到更好的提前规划。
            </p>
          </div>
          <div className="desc desc3">
            <h2>
              生活管家，<br/>记录你的生活。
            </h2>
            <h3>事项整理</h3>
            <p>
              有些事项你已完成，有些事还不太重要，时光序规划了多种整理细分方法，让事项清晰便于查看。
            </p>
            <h3>实用功能</h3>
            <p>
              在这里，有专用的还款记录工具、记账工具、专注工具、生理期工具等等……你的订阅，会使时光序成为你的贴心生活管家。
            </p>
          </div>
          <div className="desc desc4">
            <h2>
              时光序，<br/>时刻陪伴你左右。
            </h2>
            <p>
              从记录到规划，时光序能帮你把一切打理得井井有条，你可以充分享受高效生活的乐趣。
            </p>
            <div className="buttons">
              <a className="button apple store" href="https://apps.apple.com/cn/app/%E6%97%B6%E5%85%89%E5%BA%8F-%E6%97%A5%E7%A8%8B%E6%B8%85%E5%8D%95%E8%AE%A1%E5%88%92%E6%8F%90%E9%86%92%E4%BA%8B%E9%A1%B9/id1343731648#?platform=iphone" target="_blank" rel="noopener noreferrer">
                <i className="apple"></i>
                iOS
              </a>
              <a className="button android store" download href="https://livelihood.oss-cn-shenzhen.aliyuncs.com/apk/app-update-release.apk" target="_blank" rel="noopener noreferrer">
                <i className="android"></i>
                Android
              </a>
              <span className="button qr" ref={this.qrRef} onMouseEnter={this.toggleQR} onMouseLeave={this.toggleQR}>
                <span className="qr-code"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}