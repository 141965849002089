import React from 'react';

import videoUrl from '../../../../assets/opening_animation.mp4';
import './index.scss';

export default class OpeningAnimation extends React.Component {
  render() {
    const className = this.props.hidden ? 'opening-animation hidden' : 'opening-animation';
    return (
      <div className={className}>
        <video muted autoPlay src={videoUrl} onEnded={this.props.videoEndHandler}>
        </video>
      </div>
    );
  }
}