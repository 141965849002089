import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';

import Home from './home';
import FunctionIntro from './functionIntro';
import About from './about';
import NotFound from './404';

function MainPage (props) {
  return (
    <div className="main">
      <Header location={props.location} history={props.history}/>
      <Switch>
        <Route exact path={['/', '/home']} component={Home}/>
        <Route exact path="/functionIntro" component={FunctionIntro}/>
        <Route exact path="/about" component={About}/>
        <Route component={NotFound}/>
      </Switch>
      <Footer location={props.location}/>
    </div>
  );
}

export default MainPage;