import React from 'react';
import Indicator from './components/indicator';
import Content from './components/content/refactoring';
import util from '../../utils';

import './index.scss';

export default class FunctionIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0
    };
  }
  componentDidMount() {
  }
  // 滚动事件处理器
  scrollHandler = (e) => {
    const direction = e.deltaY < 0 ? 'up' : 'down';
    // 向上
    let currentIndex = 0;
    if (direction === 'up') {
      if (this.state.currentIndex === 0) {
        currentIndex = 4;
      } else {
        currentIndex = this.state.currentIndex - 1;
      }
    } else {
      // 向下
      if (this.state.currentIndex === 4) {
        currentIndex = 0;
      } else {
        currentIndex = this.state.currentIndex + 1;
      }
    }
    this.setState({currentIndex});
  }
  // 节流滚动事件处理器
  throttleScroll = util.throttle(this.scrollHandler, 500)
  render() {
    return (
      <div className="function-intro" onWheel={this.throttleScroll}>
        <div className={'top-circle page' + this.state.currentIndex}></div>
        <div className={'bottom-circle page' + this.state.currentIndex}></div>
        <Content currentIndex={this.state.currentIndex} />
        <Indicator pageNum={5} currentIndex={this.state.currentIndex} />
      </div>
    );
  }
}