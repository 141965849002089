const debounce = (func, wait, immediate) => {
	let timeout;
	return function() {
		const context = this, args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const throttle = (fn, threshhold = 500) => {

  let start = new Date();

  return function () {
    const context = this, args = arguments, curr = new Date() - 0

    if (curr - start >= threshhold) {
      fn.apply(context, args)
      start = curr
    }
  }
 }

export default { debounce, throttle }