import React from 'react';

import './index.scss';

export default class NotFound extends React.Component {
  backHome = () => {
    this.props.history.push('/home');
  }
  render () {
    return(
      <div className="not-found">
        <div className="small-circle"></div>
        <div className="big-circle"></div>
        <div className="content">
          <span className="icon"></span>
          <p className="desc-zh">Error 404 无法访问此网站</p>
          <p className="desc-en">Oops. The page you were looking for doesn’t exist.</p>
          <span className="back-home" onClick={this.backHome}>点击返回首页</span>
        </div>
      </div>
    );
  }
}