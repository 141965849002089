import React from 'react';

import './index.scss';

export default class Indicator extends React.Component {
  render() {
    const {pageNum, currentIndex} = this.props;
    const element = [];
    for (let i = 0; i < pageNum; i++) {
      const className = i === currentIndex ? 'active' : '';
      const item = <li key={i} className={className}></li>;
      element.push(item);
    }
    return (
      <ul className="indicator">
        {element}
      </ul>
    );
  }
}